jQuery(document).ready(function(){
  (function () {
    (function (jQuery) {
      'use strict';
      var requestInProcess = false;
      var floatingLabel = function (onload) {
        var $input = jQuery(this);
        if (onload) {
          jQuery.each(jQuery('.parsley-flabels-wrap input, .parsley-flabels-wrap textarea'), function (index, value) {
            var $current_input = jQuery(value);
            if ($current_input.val()) {
              $current_input.closest('.parsley-flabels-wrap').addClass('parsley-flabel-float');
            }
          });
        }
        setTimeout(function () {
          if ($input.val()) {
            $input.closest('.parsley-flabels-wrap').addClass('parsley-flabel-float');
          } else {
            $input.closest('.parsley-flabels-wrap').removeClass('parsley-flabel-float');
          }
        }, 1);
      };

      jQuery('.parsley-flabels-wrap input, .parsley-flabels-wrap textarea').keydown(floatingLabel);
      jQuery('.parsley-flabels-wrap input, .parsley-flabels-wrap textarea').change(floatingLabel);
      window.addEventListener('load', floatingLabel(true), false);

      jQuery('.js-flabels').parsley().on('form:error', function () {
        jQuery.each(this.fields, function (key, field) {
          if (field.validationResult !== true) {
            field.$element.closest('.parsley-flabels-wrap').addClass('parsley-flabels-error');
          }
        });
      });

      jQuery('.js-flabels').parsley().on('field:validated', function () {
        if (this.validationResult === true) {
          this.$element.closest('.parsley-flabels-wrap').removeClass('parsley-flabels-error');
        } else {
          this.$element.closest('.parsley-flabels-wrap').addClass('parsley-flabels-error');
        }
      }).on('form:submit', function() {
        if ( !requestInProcess ) {
          var name = jQuery('#sp-form-name').val();
          var email = jQuery('#sp-form-email').val();
          var message = jQuery('#sp-form-message').val();
          var recaptcha = jQuery('#g-recaptcha-response').val();
          var dataString = 'name=' + name + '&email=' + email + '&message=' + message + '&recaptcha=' + recaptcha;
          console.log( dataString );
          requestInProcess = true;
          // AJAX Code To Submit Form.
          jQuery.ajax({
            type: 'POST',
            url: 'mailer.php',
            data: dataString,
            cache: false,
            success: function(result){
              jQuery( '.parsley-flabels' ).hide();
              jQuery( '#sp-contact-form' ).append( result );
              requestInProcess = false;
            }
          });

          return false;
        }
      });

      jQuery( '#sp-contact-form' ).on( 'click', '#try-again', function() {
        jQuery( '.sp-ajax-response' ).slideUp( 400, function() {
          jQuery( '.sp-ajax-response' ).remove();
        });
        jQuery( '.parsley-flabels' ).slideDown( 400 );
        // Reset the recaptcha
        grecaptcha.reset();
      });

    }(jQuery));
  }.call(this));
});
