jQuery( document ).ready( function($) {
  var $window = $( window );
  var $document = $( document );

	//trigger the animation - open modal window
	$( '[data-type="modal-trigger"]' ).on( 'click', function() {
		var $actionBtn = $(this);
    var $modalBg = $actionBtn.next( '.sp-portfolio__modal-bg' );
		var scaleValue = retrieveScale( $modalBg );

		$actionBtn.addClass( 'to-circle' );
		$modalBg.addClass( 'is-visible' ).one( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
			animateLayer( $modalBg, scaleValue, true );
		});

		//if browser doesn't support transitions...
		if ( $actionBtn.parents( '.no-csstransitions' ).length > 0 ) {
      animateLayer( $modalBg, scaleValue, true );
    }
	});

	//trigger the animation - close modal window
	$( '.sp-portfolio__modal-close' ).on( 'click', function() {
		closeModal();
	});
	$document.keyup( function( event ) {
		if ( '27' === event.which ) {
      closeModal();
    }
	});

	$window.on( 'resize', function() {
		//on window resize - update cover layer dimension and position
		if ( $( '.sp-portfolio__item.modal-is-visible' ).length > 0 ) {
      window.requestAnimationFrame( updateLayer );
    }
	});

	function retrieveScale( btn ) {
		var btnRadius = btn.width() / 2;
		var left = btn.offset().left + btnRadius;
		var top = btn.offset().top + btnRadius - $window.scrollTop();
		var scale = scaleValue(top, left, btnRadius, $window.height(), $window.width());

		btn.css( 'position', 'fixed' ).velocity({
			top: top - btnRadius,
			left: left - btnRadius,
			translateX: 0,
		}, 0);

		return scale;
	}

	function scaleValue( topValue, leftValue, radiusValue, windowW, windowH ) {
		var maxDistHor = ( leftValue > windowW / 2 ) ? leftValue : ( windowW - leftValue );
		var maxDistVert = ( topValue > windowH / 2 ) ? topValue : ( windowH - topValue );
		return Math.ceil( Math.sqrt ( Math.pow( maxDistHor, 2 ) + Math.pow( maxDistVert, 2 ) ) / radiusValue );
	}

	function animateLayer( layer, scaleVal, bool ) {
		layer.velocity({ scale: scaleVal }, 400, function() {
			$( 'body' ).toggleClass( 'overflow-hidden', bool );
			(bool)
				? layer.parents( '.sp-portfolio__item' ).addClass( 'modal-is-visible' ).end().off( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend' )
				: layer.removeClass( 'is-visible' ).removeAttr( 'style' ).siblings( '[data-type="modal-trigger"]' ).removeClass( 'to-circle' );
		});
	}

	function updateLayer() {
		var layer = $( '.sp-portfolio__item.modal-is-visible' ).find( '.sp-portfolio__modal-bg' );
		var layerRadius = layer.width() / 2;
		var layerTop = layer.siblings( '.sp-portfolio__modal-button' ).offset().top + layerRadius - $window.scrollTop();
		var layerLeft = layer.siblings( '.sp-portfolio__modal-button' ).offset().left + layerRadius;
		var scale = scaleValue( layerTop, layerLeft, layerRadius, $window.height(), $window.width() );

		layer.velocity({
			top: layerTop - layerRadius,
			left: layerLeft - layerRadius,
			scale: scale,
		}, 0);
	}

	function closeModal() {
		var section = $( '.sp-portfolio__item.modal-is-visible' );
		section.removeClass( 'modal-is-visible' ).one( 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
			animateLayer( section.find( '.sp-portfolio__modal-bg' ), 1, false);
		});
		//if browser doesn't support transitions...
		if( section.parents( '.no-csstransitions' ).length > 0 ) animateLayer( section.find( '.sp-portfolio__modal-bg' ), 1, false );
	}
});
